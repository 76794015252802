import { useEffect, useState } from 'react';
import { getAccount } from '@innhold/core/account';
import { getCmUrl } from '@innhold/core/cm';

export const SalesStripe = () => {
    const [stripeContent, setStripeContent] = useState(null);

    useEffect(() => {
        const fetchSalesStripe = async () => {
            let cmUrl = getCmUrl('stripe');

            const account = await getAccount();
            const user = await account.getUser();
            if (user) {
                cmUrl += `?spid=${user.userId}`;
            }

            const headers = {};
            const parts = document.cookie.split('; abx2=');
            if (parts.length === 2) {
                headers['x-abx'] = parts.pop().split(';').shift();
            }

            const response = await fetch(cmUrl, { credentials: 'include', headers });
            if (response.ok) {
                const body = await response.json();
                const { content = null } = body.data;

                if (content) {
                    setStripeContent(content);
                }
            }
        };

        fetchSalesStripe();
    }, []);

    return stripeContent ? (
        <div
            style={{ marginTop: '8px', marginBottom: '12px' }}
            dangerouslySetInnerHTML={{ __html: stripeContent }}
        />
    ) : null;
};
